import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faMobileAlt,
  faEnvelopeOpen,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { ReactSVG } from "react-svg";

import "./Contact.css";
import svg from "../images/logo1.svg";
import artisanatCertifie from "../images/artisanat-certifie.png";

export const Contact = () => {
  const iconStyle = {
    textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
    marginRight: 10,
  };
  return (
    <section className="contact">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <ReactSVG src={svg} />
            <i>Atelier de Verre Soufflé</i>
            <br />
            <i>Atelier de Céramique et Tournage</i>
          </div>
          <div className="col-12 col-md-4">
            <dl>
              <dd>
                <FontAwesomeIcon
                  size="2x"
                  icon={faMapMarkedAlt}
                  style={iconStyle}
                />
                Rue Lumsonry, 57 B-5651 TARCIENNE
              </dd>
              <dd>
                <FontAwesomeIcon size="2x" icon={faPhone} style={iconStyle} />
                Téléphone : + 32 (0)71 21 74 07
              </dd>
              <dd>
                <FontAwesomeIcon
                  size="2x"
                  icon={faMobileAlt}
                  style={iconStyle}
                />
                Portable : + 32 (0)496 60 85 33
              </dd>
              <dd>
                <FontAwesomeIcon
                  size="2x"
                  icon={faEnvelopeOpen}
                  style={iconStyle}
                />
                Email : b.vaisiere@live.be
              </dd>
            </dl>
          </div>
          <div className="col-12 col-md-4">
            <img
              src={artisanatCertifie}
              style={{ width: "50%" }}
              alt="Artisanat Certifie"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
