import { format } from "date-fns";

import "./Footer.css";

export const Footer = () => {
  return (
    <footer className="footer">
      Copyright ©{format(new Date(), "yyyy")} TerreEtVerre
    </footer>
  );
};
