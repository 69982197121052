import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import { client } from "./utility/ApolloClient";

import { Header } from "./presentational/Header/Header";
import { Footer } from "./presentational/Footer/Footer";
import BodyContainer from "./container/BodyContainer";
import { Contact } from "./presentational/Contact";

import "./App.css";

export function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Header />
        <BodyContainer />
        <Contact />
        <Footer />
        <CookieConsent>
          En poursuivant votre navigation sur ce site, vous acceptez
          l’utilisation de cookies.
        </CookieConsent>
      </Router>
    </ApolloProvider>
  );
}
