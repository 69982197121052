import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faHome,
  faCalendarAlt,
  faNewspaper,
  faShoppingBasket,
  faFlask,
  faConciergeBell,
} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { Link } from "react-router-dom";

import "./MainNavigation.scss";

export class MainNavigation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  handleMenuClick() {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  }

  render() {
    const { isMenuOpen } = this.state;
    const disable = false;

    return (
      <nav className="main-navigation">
        <div
          className="main-navigation-toggler"
          onClick={this.handleMenuClick}
          onKeyPress={this.handleMenuClick}
          role="presentation"
        >
          <div className="bar" />
          <div className="bar" />
          <div className="bar" />
        </div>

        <div
          className={classnames("main-navigation-menu", {
            "menu-on": isMenuOpen,
          })}
        >
          <div
            className="main-navigation-close-icon"
            onClick={this.handleMenuClick}
            onKeyPress={this.handleMenuClick}
            role="presentation"
          >
            <div className="cross-wrap">
              <span className="top" />
              <span className="bottom" />
            </div>
          </div>

          <div className="main-navigation-links">
            <ul>
              <li>
                <Link to="/">
                  <FontAwesomeIcon
                    style={{
                      marginRight: 10,
                    }}
                    icon={faHome}
                  />
                  Home
                </Link>
              </li>
              <li>
                <Link to="/evenements">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    style={{
                      marginRight: 10,
                    }}
                  />
                  Événements
                </Link>
              </li>
              <li>
                <Link to="/news">
                  <FontAwesomeIcon
                    icon={faNewspaper}
                    style={{
                      marginRight: 10,
                    }}
                  />
                  Actualités
                </Link>
              </li>
              <li>
                <Link to="/shop">
                  <FontAwesomeIcon
                    icon={faShoppingBasket}
                    style={{
                      marginRight: 10,
                    }}
                  />
                  Magasin
                </Link>
              </li>
              {disable && (
                <li>
                  <Link to="/stages">
                    <FontAwesomeIcon icon={faCoffee} />
                    <FontAwesomeIcon
                      size="1x"
                      icon="flask"
                      style={{
                        marginRight: 10,
                      }}
                    />
                    Stages
                  </Link>
                </li>
              )}
              {disable && (
                <li>
                  <Link to="/visites">
                    <FontAwesomeIcon
                      icon={faFlask}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    Portes ouvertes
                  </Link>
                </li>
              )}
              {disable && (
                <li>
                  <Link to="/contact">
                    <FontAwesomeIcon
                      icon={faConciergeBell}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    Contact
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
