import React from "react";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

import classnames from "classnames";

import "./TopSocialArea.scss";

export const TopSocialArea = ({ isHomepage }) => {
  const topsocialareaClasses = classnames("topsocialarea", {
    "is-homepage": isHomepage,
  });
  return (
    <div className={topsocialareaClasses}>
      <ReactTooltip />
      <a
        href="https://www.facebook.com/terreetverre/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FontAwesomeIcon
          size="2x"
          data-tip="Facebook"
          data-place="bottom"
          icon={faFacebook}
        />
      </a>
    </div>
  );
};
