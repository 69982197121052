import React from "react";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";

import { TopSocialArea } from "../TopSocialArea/TopSocialArea";
import { MainNavigation } from "../MainNavigation/MainNavigation";
import { VideoOverlay } from "../VideoOverlay/VideoOverlay";

import "./Header.scss";

export const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const { innerHeight } = window;
  const sticky = true;
  const isHomepage = pathname === "/";
  const headerClasses = classnames("header", {
    "is-sticky": sticky,
    "is-homepage": isHomepage,
  });

  return (
    <header className={headerClasses}>
      <section className="header_top">
        <div className="header_top_left">
          <Link to="/">Terre Et Verre</Link>
        </div>
        <div className="header_top_right">
          <TopSocialArea isHomepage={isHomepage} />
        </div>
      </section>

      {isHomepage && <VideoOverlay innerHeight={innerHeight} />}

      <section className="header_nav">
        <MainNavigation />
      </section>
    </header>
  );
};

// constructor(props) {
//   super(props);
//   const { innerHeight } = window;
//   this.state = { sticky: false, innerHeight };
//   this.onScroll = this.onScroll.bind(this);
// }

// onScroll() {
//   const { sticky } = this.state;
//   if (window.scrollY >= 565 && !sticky) {
//     this.setState({ sticky: true });
//   } else if (window.scrollY < 565 && sticky) {
//     this.setState({ sticky: false });
//   }
// }
