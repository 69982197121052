import React from "react";
import SVG from "react-inlinesvg";

import "./VideoOverlay.scss";

import logoMobile from "../../images/logo-mobile.svg";
import logoTablet from "../../images/logo-tablet.svg";
import logoDesktop from "../../images/logo-desktop.svg";

export const VideoOverlay = ({ innerHeight }) => {
  const style = {
    height: innerHeight - 130,
  };
  return (
    <section className="video" style={style}>
      <div className="cd-intro-content">
        <h1 className="svg-wrapper">
          <SVG className="mobile" src={logoMobile} />
          <SVG className="tablet" src={logoTablet} />
          <SVG className="desktop" src={logoDesktop} />
        </h1>
        <p className="text1">Decouvrez nos créations et activités</p>
        <p className="text2">
          Notre passion: la terre et le verre et transmettre notre savoir
        </p>
      </div>
    </section>
  );
};
